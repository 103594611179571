import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, LogoSpreadIcon } from 'components/icons'
import { Nav } from 'components/nav'
import { ATeamIcon, Belief, BeliefDesc, CraftIcon, FWordsIcon, SolveProblemIcon, StatusQuoIcon, TeamUpIcon } from 'components/pages/ManifestoPage'
import { LdJsonLogo, HeaderLink } from '../components/semanticdata'
import Analytics from '../components/analytics'

const ManifestoPage = () => (
  <Layout className="bg-primary-2">
    <Analytics />
    <SEO title="Manifesto" pageUrl="/manifesto/" type="article" description="We keep it simple. We are here to do the things we love and we stand by every word in our manifesto!" />
    <LdJsonLogo />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6">
        <HeaderLink />
        <div className="pt-12 pb-32 text-left text-primary-3">
          <h1 className="pt-4 pb-4 text-4xl lg:text-5xl">Manifesto</h1>
          <p className="text-xl sm:text-2xl lg:text-3xl leading-tight">
            Bulgaria has developed into one of the world's major technology hubs over the past 20 years. To meet the increasing demand most IT companies have started working only with a view on deliverables and timelines, disregarding a major aspect of our
            work: the need to contribute with ideas, further develop our knowledge and create amazing software.
          </p>
          <p className="py-3 text-xl sm:text-2xl lg:text-3xl">We can do more. We can be more.</p>
          <p className="py-3 text-xl sm:text-2xl lg:text-3xl text-primary-1 ">This is our manifesto.</p>
        </div>
      </Header>
      <Deco before after decoType="3" className="bg-primary-3 h-full v-full" gridRow={2} />
      <Section className="z-20 mb-24 text-primary-2" gridRow={2}>
        <StatusQuoIcon className="h-24 sm:h-40" />
        <Belief>1. Challenge the status quo</Belief>
        <BeliefDesc>
          We founded Tumba Solutions in 2014 as the ultimate response to those IT company demands. We came together as an A-Team of experienced developers who share a passion to co-create with our clients, nourish our insatiable curiosity through constant
          learning and never provide pure body shop. Thus we introduced a paradigm shift: The world to perceive Bulgaria not only as an outsourcing destination, but as a land of innovation where expertise and out-of-the-box thinking beat cost.
        </BeliefDesc>
        <TeamUpIcon className="mt-24 h-24 sm:h-40" />
        <Belief>2. Team up as Tumba</Belief>
        <BeliefDesc>
          Besides a mountain peak in Belasitsa, Tumba is our metaphor for group of smart, nimble and allied professionals / akin to each other. The autonomy each one of us has, alongside the support of the rest of the team, makes us able to develop our own
          skills, earn new knowledge and shape the future of our company together.
        </BeliefDesc>
        <Link to="/careers" className="mt-6 flex items-center text-primary-1">
          <span className="uppercase font-normal tracking-normal text-sm">careers</span>
          <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />
        </Link>
        <ATeamIcon className="mt-16 h-24 sm:h-40" />
        <Belief>3. Have an A-player mindset</Belief>
        <BeliefDesc>
          There's no room for mediocrity and zealots at Tumba. We are in this together. Everyone in Tumba feels equally engaged and responsible as the business owner. We have an integrated sense of urgency and strive for efficiency, which allows us to deliver
          optimal product growth and business value. Top-notch technology solutions is our thing. We generate ideas about best-use of technologies and algorithms, understand end-user requirements, formulate use cases, and develop pragmatic and effective
          integration.
        </BeliefDesc>
        <SolveProblemIcon className="mt-24 h-24 sm:h-40" />
        <Belief>4. Solve the right kind of problems</Belief>
        <BeliefDesc>
          We love technical challenges that teach skill and mastery! You may find us working late just because we want to find a better way to solve a tough problem. We like getting things done but we don't waste time on problems that don't matter – fixing the
          code of a junior developer somewhere in the world is not what we do.
        </BeliefDesc>
        <CraftIcon className="mt-24 h-24 sm:h-40" />
        <Belief>5. See code as a craft</Belief>
        <BeliefDesc>
          Craftsmen see their work as a piece of themselves - a representation of their highest creativity and technical skill. In the same way, we like to see our code materialize in beautiful and functional software. Growth and learning are not just clichés
          to us. We embrace learning and practicing new technologies and methods, when they are the best fit for the job. We won't get stuck in a particular technology, just because it's in our comfort zone.
        </BeliefDesc>
        <FWordsIcon className="mt-24 h-24 sm:h-40" />
        <Belief>6. Learn the F words</Belief>
        <BeliefDesc>
          Hierarchy and structure might come up as words in our developer talk but they are never part of our conversations when we speak about Tumba Solutions's culture. We live a culture of open communication, where each one of us is ultimately responsible
          for their own evolution of roles, responsibilities. Freedom and flexibility are our common F* words. To a strict corporate culture, we say another kind of F*.
        </BeliefDesc>
      </Section>
      <Section className="z-20 mt-40" gridRow={3}>
        <LogoSpreadIcon className="m-auto h-20" />
      </Section>
      <Deco className="bg-primary-1" gridRow={3} />
      <Nav className="z-20 text-primary-2" gridRow={4} />
    </MainWithMenu>
  </Layout>
)

export default ManifestoPage
